<template>
  <v-card data-intercom-target="PrescriptionList">
    <v-card-title>
      <p class="tw-text-blue-900 tw-text-2xl tw-font-bold">
        Prescription List (Last 365 days)
      </p>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-2"
        color="primary"
        @click="cancel"
      >
        Clear
      </v-btn>
      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedMedication"
      :search="search"
      :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, -1]}"
      disable-items-per-page
      :headers="headers"
      :items="detailedMedicationsList"
      :items-per-page="30"
      item-key="RxNumber"
      :single-select="singleSelect"
      show-select
      fixed-header
      event
      sort-by="RxNumber"
      :sort-desc="[true]"
      :loading="loading"
      @click:row="rowClick"
    >
      <template
        #[`item.selectedDrug`]="{ item }"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="selectRow(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template
        #[`item.rphQuantity`]="{ item }"
      >
        <!-- YOU ARE HERE WORKING ON VALIDATION FOR NUMBERS ONLY -->
        <!-- <v-text-field
          v-model="item.rphQuantity"
          class="tw-bg-blue-600 tw-bg-opacity-20 focus:tw-ring-4 focus:tw-ring-pgblue"
          type="number"
          :rules="[rules.number]"
          number
        ></v-text-field> -->
        <!-- <FormulateInput
          v-model="item.rphQuantity"
          class="tw-pt-4"
          type="text"
          name="Quantity"
          validation="number"
          error-behavior="live"
        /> -->
        <div class="tw-pt-4">
          <v-text-field
            v-model="item.rphQuantity"
            :autofocus="autofocus"
            label="Quantity"
            outlined
            dense
            type="number"
          ></v-text-field>
        </div>
      </template>
      <template
        #[`item.rphRepeat`]="{ item }"
      >
        <!-- <v-text-field
          v-model="item.rphRepeat"
          class="tw-bg-blue-600 tw-bg-opacity-20 focus:tw-ring-4 focus:tw-ring-pgblue"
          type="number"
          :rules="[rules.number]"
          number
        ></v-text-field> -->
        <div class="tw-pt-4">
          <v-text-field
            v-model="item.rphRepeat"
            label="Repeat"
            outlined
            dense
            type="number"
          ></v-text-field>
        </div>
        <!-- <FormulateInput
          v-model="item.rphRepeat"
          class="tw-pt-4"
          type="text"
          name="Repeats"
          validation="number"
          error-behavior="live"
        /> -->
      </template>
      <template #[`item.hold`]="{item}">
        <v-chip
          :hidden="!item.hold"
          small
          class="v-chip-light-bg"
          :color="statusList[item.hold] || ''"
          :class="`${statusList[item.hold]}--text`"
        >
          {{ translateList[item.hold] }}
        </v-chip>
      </template>
      <template #[`item.Status`]="{item}">
        <v-chip
          :hidden="!item.Status"
          small
          class="v-chip-light-bg"
          :color="statusList[item.Status] || ''"
          :class="`${statusList[item.Status]}--text`"
        >
          {{ translateList[item.Status] }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js'
import { momentTimeZone } from '@/util/functions/index'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MultiDrugRepeat',
  props: ['selected'],
  setup() {
    const statusColor = { 1: 'warning' }

    return { statusColor }
  },
  data() {
    return {
      statusList: {
        1: 'orange',
        COM: 'success',
        AMD: 'success',
        CAN: 'warning',
        HOL: 'orange',
        I: 'error',
        F: 'primary',
        CH: 'warning',
        T: 'grey',
      },
      translateList: {
        1: 'Hold',
        COM: 'Complete',
        AMD: 'Amended',
        CAN: 'Cancelled',
        HOL: 'Hold',
        I: 'Inactive',
        F: 'New Rx',
        CH: 'CAN/HOL',
        T: 'Transferred Out',
      },
      loading: false,
      autofocus: true,
      detailedMedicationsList: [],
      selectedMedication: this.selected || [],
      search: '',
      RxNumber: '',
      singleSelect: false,
      rowIndex: [],
      rules: {
        number: value => !!Number.isNaN(value) || 'Enter a number.',
      },

      // groupby: 'GenericName',
      headers: [
        {
          text: 'Quantity',
          value: 'rphQuantity',
          groupable: false,
          width: '10%',
          align: 'center',
        },

        {
          text: 'Repeat',
          value: 'rphRepeat',
          groupable: false,
          width: '10%',
          align: 'center',
        },
        {
          text: 'Brand Name',
          value: 'BrandName',
          align: 'center',
        },

        // {
        //   text: 'Generic Name',
        //   value: 'GenericName',
        //   sortable: false,
        // },
        {
          text: 'Strength',
          value: 'RxStrength',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Dr Last Name',
          value: 'LastName',
        },
        {
          text: 'Date',
          value: 'RxDate',
          groupable: false,
          formatter: x => (x ? momentTimeZone(x) : null),
        },
        {
          text: 'Rx Number',
          value: 'RxNumber',
          groupable: false,
        },

        {
          text: 'Rx Status',
          value: 'Status',
          groupable: false,
          width: '5%',
        },
      ],
      icons: {
        mdiPencilOutline,
      },
    }
  },
  watch: {
    selectedMedication() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  created() {
    this.getSearchByRx()
  },
  methods: {
    cancel() {
      this.selectedMedication = []
    },
    rowClick(item, row) {
      if (this.rowIndex.includes(row.index)) {
        this.rowIndex = this.rowIndex.filter(rowIndex => rowIndex !== row.index)
        row.select(false)
      } else {
        row.select(true)
        this.rowIndex.push(row.index)
      }
    },
    async getSearchByRx() {
      this.loading = true
      this.detailedMedicationsList =
        (await this.$root.$system.DBAdapterConn.getSingleRecentRx(
          this.PatientProfile.PatientID,
          this.rxNumber,
          this.genericName,
          this.brandName,
          this.mixName,
          this.LastName,
          this.FirstName,
        )) || []
      this.loading = false
    },
    select() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  computed: {
    ...mapGetters(['PatientProfile']),
    ...mapState(['User']),
  },
}
</script>
